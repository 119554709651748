import { Injectable } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

@Injectable({
  providedIn: 'root'
})
export class ArraysServices {
 
  moverArray(array : any[], posicionInicial:number, posicionFinal:number) {
    if (posicionFinal >= array.length) {
        var k = posicionFinal - array.length + 1;
        while (k--) {
          array.push(undefined);
        }
    }
    array.splice(posicionFinal, 0, array.splice(posicionInicial, 1)[0]);
    return array;
  };

  moverArrayDrop(event: CdkDragDrop<string[]> , arrayCall:() => void , transferCall? : () => void){
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      arrayCall();
    }else{
      transferArrayItem(event.previousContainer.data,event.container.data,event.previousIndex,event.currentIndex);
      transferCall();
    }
  }
  
}

export  abstract  class GeneralServices {
  
  public static ComparadorTernario(comparacion:boolean , verdadero:any , falso:any){
    return (comparacion) ? verdadero : falso;
  }

  public static LimpiarRuta(value:string){
    return encodeURIComponent(value).replace(/%20/g ,'-').replace(/"/g ,"'");
  }

}


export class SesionService {

  private almacenamiento:any = localStorage;

  private decodificarJWT(token: Response ): any { 
    try{
       // return jwt_decode(token);
    }
    catch(Error){
        return null;
    }
  }

  reasignarItemAlmacen(titulo : string , valor:any){
    if(this.almacenamiento.getItem(titulo) != null || this.almacenamiento.getItem(titulo) != undefined){
       this.almacenamiento.setItem(titulo , valor );
    }
    
  }

  asignarItemAlmacen(titulo : string , valor:any){
    this.almacenamiento.setItem(titulo , valor );
  }

  obtenerItemAlmacen(titulo : string){
    return this.almacenamiento.getItem(titulo);
  }

  

  desSerializarItem(item : string){
    
    return this.decodificarJWT(this.obtenerItemAlmacen(item));
  }

  eliminarItemAlmacen(titulo : string){
    this.almacenamiento.removeItem(titulo);
  }

  limpiarAlmacen(){
    this.almacenamiento.clear();
    this.asignarItemAlmacen("access_user_testing","true");
  }

}



