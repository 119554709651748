import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, filter, switchMap  } from 'rxjs/operators';
import { Response } from 'src/app/interfaces/general/response'
import { ServicioPadre } from '../_general_/servicioPadre.service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Injectable({
  providedIn: 'root'
})

export class ConstantesService extends ServicioPadre {
  private HEADERS = new HttpHeaders().set('Content-Type', 'application/json');
  private TABLE = 'constantes';

  constructor(private httpClient: HttpClient , public SnackBar:MatSnackBar ) {
    super(httpClient,SnackBar);
  }

  public  Obtener(nombre : string) : any {
    return  this.PeticionPOST(this.TABLE+'/Obtener' , { 'nombre' :nombre }).then(data => data.datos.valor);
  }
  

}

