import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, filter, switchMap  } from 'rxjs/operators';
import { RUTAS } from 'src/app/services/consts/rutas';
import { Response } from 'src/app/interfaces/general/response'
import { Observable } from 'rxjs';
import { SUPER_EXPR } from '@angular/compiler/src/output/output_ast';
import { ServicioPadre } from '../_general_/servicioPadre.service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Injectable({
  providedIn: 'root'
})

export class ProductoService extends ServicioPadre {
  private HEADERS = new HttpHeaders().set('Content-Type', 'application/json');
  private TABLE = 'producto';

  constructor(private httpClient: HttpClient , public SnackBar:MatSnackBar ) {
    super(httpClient,SnackBar);
  }

  public async Agregar(ProductoForm) : Promise<Response<any>> {
    return await this.PeticionPOST(this.TABLE+'/registrar' , ProductoForm);
  }

  public async listaFruver(ProductoForm) : Promise<Response<any>> {
    return await this.PeticionPOST(this.TABLE+'/listaFruver' , ProductoForm);
  }

  public async ActualizarStock(ProductoForm) : Promise<Response<any>> {
    return await this.PeticionPOST(this.TABLE+'/actualizarStock' , ProductoForm);
  }
  
  public async ActualizarPrecio(ProductoForm) : Promise<Response<any>> {
    return await this.PeticionPOST(this.TABLE+'/actualizarPrecio' , ProductoForm);
  }

  public async ComentarProducto(ProductoForm) : Promise<Response<any>> {
    return await this.PeticionPOST(this.TABLE+'/comentar' , ProductoForm);
  }

  public async ListaMisComentarios(ProductoForm) : Promise<Response<any>> {
    return await this.PeticionPOST(this.TABLE+'/ListaMisComentarios' , ProductoForm);
  }

  

  public async ProductoDetalle(id) : Promise<Response<any>> {
    return await this.PeticionPOST(this.TABLE+'/detalle' , id);
  }
  

  public async Actualizar(ProductoForm) : Promise<Response<any>> {
    return await this.PeticionPOST(this.TABLE+'/actualizar' , ProductoForm);
  }
  
  public async ListaMisProductosFiltro(FiltroData) : Promise<Response<any>> {
    return await this.PeticionPOST(this.TABLE+'/filtroMisProductos' , FiltroData);
  }
  

  public async ActualizarEstado(ProductoVo) : Promise<Response<any>> {
    return await this.PeticionPUTDatos(this.TABLE+'/estado' , ProductoVo);
  }

  public async ObtenerPorId(id) : Promise<Response<any>> {
    return await this.PeticionPOST(this.TABLE+'/obtener' , id);
  }
  
  public async TodosProductos(ProductoVo) : Promise<Response<any>> {
    return await this.PeticionPOST(this.TABLE+'/TodosProductos' ,ProductoVo);
  }

  public async TodosProductosRelacionados(ProductoVo) : Promise<Response<any>> {
    return await this.PeticionPOST(this.TABLE+'/TodosProductosRelacionados' ,ProductoVo);
  }


  public async TodasSubPresentaciones(idProducto) : Promise<Response<any>> {
    return await this.PeticionPOST(this.TABLE+'/SubPresentaciones' , {idTpoPresentacion : idProducto});
  }


  public async ListaProductos(ProductoForm) : Promise<Response<any>> {
    return await this.PeticionPOST(this.TABLE+'/ListaProductos' , ProductoForm);
  }


  

 

}

