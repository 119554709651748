import { Component, OnInit, Input} from '@angular/core';
import { CategoriasService } from 'src/app/services/rest/categorias/CategoriaService';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  public categorias:any[] = [];
  constructor(public categoriaServices :CategoriasService) { }

  ngOnInit() { 
    this.Categorias();
  }

  public async Categorias(){
    await this.categoriaServices.TodasCategorias().then(resp => {
      this.categorias = resp.datos;
    });
  }


  openMegaMenu(){
    let pane = document.getElementsByClassName('cdk-overlay-pane');
    [].forEach.call(pane, function (el) {
        if(el.children.length > 0){
          if(el.children[0].classList.contains('mega-menu')){
            el.classList.add('mega-menu-pane');
          }
        }        
    });
  }

}
