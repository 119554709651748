import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetallesCompraComponent } from './detalles-compra.component';
import { MatInputModule } from '@angular/material/input';
import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';



@NgModule({
  entryComponents : [
    DetallesCompraComponent
  ],
  declarations: [DetallesCompraComponent],
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatInputModule,
    MatTableModule,
  ],
  exports : [
    DetallesCompraComponent
  ]
})
export class DetallesCompraModule { }
