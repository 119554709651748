import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { AgmCoreModule } from '@agm/core';
import { MapasComponent } from './mapas/mapas.component';
import { RecuperarContrasenaComponent } from './recuperar-contrasena/recuperar-contrasena.component';
import { TiposProductosComponent } from './tipos-productos/tipos-productos.component';
import { ListaCiudadesComponent } from './lista-ciudades/lista-ciudades.component';


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    AgmCoreModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    RecuperarContrasenaComponent,
    TiposProductosComponent,
    MapasComponent,
    ListaCiudadesComponent
  ]
})
export class ModalModule { }
