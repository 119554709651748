import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SesionService } from 'src/app/services/utils/general/general.service';
import { EventEmitter } from '@angular/core';


@Injectable()

export class SignInServices {

    constructor(public sesionServ: SesionService) { }

    public estaLogeado = new EventEmitter<boolean>();
    public datos = new EventEmitter<object>();

    public ReactivarService(datos: Object): Observable<any> {
        return new Observable((observer) => {
            const handler = (e) => observer.next(e);
            if (this.sesionServ.obtenerItemAlmacen("__u0s1e2r3@__") === null && datos != null ) {
                this.estaLogeado.emit(true);
                this.sesionServ.asignarItemAlmacen("__u0s1e2r3@__", datos)
            } else {
                this.sesionServ.eliminarItemAlmacen("__u0s1e2r3@__");
                this.estaLogeado.emit(false);
            }
        });
    }

    public RetormarSiExisteSesion() : boolean{
        if (this.sesionServ.obtenerItemAlmacen("__u0s1e2r3@__") === null) {
            return false;
        } else {
            return true;
        }
    }

    public RetormarDatos() : any{
        if (this.sesionServ.obtenerItemAlmacen("__u0s1e2r3@__") === null) {
            return { id_publico : null,
                nombres : '',
                direccion : '',
                celular : '',
                id_estado : 0,
                calificacion : 0,
                foto : null};
        } else {
           return  JSON.parse(this.sesionServ.obtenerItemAlmacen("__u0s1e2r3@__"));
        }
    }

}

