import { Component, OnInit, Input } from '@angular/core';
import { CarritoService } from 'src/app/services/rest/carrito/CarritoService';
import { ConfiguracionDatosBasicos } from 'src/app/app.settings';
import { SesionService } from 'src/app/services/utils/general/general.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppService } from 'src/app/app.service';
import { Router } from '@angular/router';
import { ProductoService } from 'src/app/services/rest/productos/ProductoService';

@Component({
  selector: 'app-presentations',
  templateUrl: './presentations.component.html',
  styleUrls: ['./presentations.component.scss']
})
export class PresentationsComponent implements OnInit {
  @Input() product: any;
  @Input("esEditable") esEditable: boolean;
  constructor(public appService: AppService, public configuracionDatosBasicos: ConfiguracionDatosBasicos, public carritoServices: CarritoService, public snackBar: MatSnackBar, public sesionServices: SesionService , private router : Router , public snack : MatSnackBar , public productoService : ProductoService) { }
  listaPresentaciones: any = [];

  ngOnInit(): void {

  }

  public async cambiarStock(product , valor) {
    await this.productoService.ActualizarStock({ id: product.idPresentacion , stock : valor}).then((resp) => {});
  }

  public async cambiarPrecio(product , valor) {
    await this.productoService.ActualizarPrecio({ id: product.idPresentacion , precio : valor}).then((resp) => {});
  }

  public GestionarTipo(product, i, tipo) {
    if (tipo == '-') {
      product.cantidades -= (product.cantidades != 0) ? 1 : 0;
    } else {
      product.cantidades += (product.cantidades != product.stock) ? 1 : 0;
    }
    this.Carrrito(product);
  }

  public async Carrrito(product) {
    if (this.configuracionDatosBasicos.datos.id_publico === null) {
      this.snack.open("Debes registrate antes de agregar el productos" , 'Aceptar' , {
        duration: 2000,
     });
      this.router.navigate(["sign-in"])
      return;
    }

    if (product.cantidades != 0) {
      let datos = JSON.parse(this.sesionServices.obtenerItemAlmacen("__u0s1e2r3@__"));
      await this.carritoServices.Insertar({ form: product, persona: datos }).then(resp => {

        if (resp.estado == 200) {
          if (resp.datos.editado == true) {
            let datos = JSON.parse(this.sesionServices.obtenerItemAlmacen("__u0s1e2r3@__"));
            datos.carrito.map((carrito) => {
              if (resp.datos.id == carrito.id) {
                carrito.cantidad = product.cantidades;
              }
            });
            this.sesionServices.reasignarItemAlmacen("__u0s1e2r3@__", JSON.stringify(datos));
            this.appService.actualizarDatosCarrito();
            this.snackBar.open('Se Actualizo el producto satisfactoriamente.', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
          } else {
           
            let datos = JSON.parse(this.sesionServices.obtenerItemAlmacen("__u0s1e2r3@__"));

            datos.carrito.push(resp.datos);
            this.sesionServices.reasignarItemAlmacen("__u0s1e2r3@__", JSON.stringify(datos));
            this.appService.actualizarDatosCarrito();
            this.snackBar.open('Se Agrego el producto satisfactoriamente.', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
          }

        }
      });
    }
  }



  ngAfterViewInit(): void {
    this.listaPresentaciones = this.product.categorias;
   
  }

}
