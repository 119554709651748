import { Component, OnInit } from '@angular/core';
import { Data, AppService } from '../../../app.service';
import { Settings, AppSettings, ConfiguracionDatosBasicos } from '../../../app.settings';
import { SignInServices  } from 'src/app/pages/sign-in/sign-in.services' ;
import { ProveedoresService } from 'src/app/services/rest/proveedores/ProveedoresService';
import { Proveedor } from 'src/app/interfaces/proveedor/Proveedor.interface';
import { SesionService } from 'src/app/services/utils/general/general.service';
import { Router } from '@angular/router';
import { CarritoService } from 'src/app/services/rest/carrito/CarritoService';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html'
})
export class TopMenuComponent implements OnInit {
  public currencyMenu: any;
  public nombreProv : string = 'seleccionar';
  public sesionIniciada: boolean = false;
  public flags = [
    { name: 'English', image: 'assets/images/flags/gb.svg' },
    { name: 'German', image: 'assets/images/flags/de.svg' },
    { name: 'French', image: 'assets/images/flags/fr.svg' },
    { name: 'Russian', image: 'assets/images/flags/ru.svg' },
    { name: 'Turkish', image: 'assets/images/flags/tr.svg' }
  ]
  public flag: any;
  public ListaProveedores:Proveedor[] = [];

  public settings: Settings;
  constructor(public appSettings: AppSettings, public appService: AppService , public signInServices:SignInServices , public configuracionDatosBasicos:ConfiguracionDatosBasicos ,public proveedorService : ProveedoresService , public sesionServices: SesionService , public router : Router  , private carritoServices : CarritoService) {
    this.settings = this.appSettings.settings;
    this.ValidarSesion();
    this.ValidarDatosModificados();
   this.sesionIniciada = this.signInServices.RetormarSiExisteSesion()
   this.configuracionDatosBasicos.datos = this.signInServices.RetormarDatos()
  }

  public ValidarSesion() {
    this.signInServices.estaLogeado.subscribe(estado => {
      this.sesionIniciada = estado
    });
  }

  public async ListaTpoProveedores(){
    await this.proveedorService.lista().then((resp)=>{
      let sesion = this.sesionServices.obtenerItemAlmacen("tpoProductoBuscar")
      if(sesion){
        let lista :any = resp.datos;
        let dataFiltro =  lista.filter(data => data.id == sesion || data.nombre == sesion );
        this.nombreProv = dataFiltro[0].nombre;
      }
      this.ListaProveedores.push(resp.datos)
    });
  }

  public ValidarDatosModificados() {
    this.signInServices.datos.subscribe(datos => {
      this.configuracionDatosBasicos.datos = datos;
    });
  }

  ngOnInit() {
    this.ListaTpoProveedores();
    this.flag = this.flags[0];
    this.ValidarSesion();
  }

  public async LogOut() {
    this.signInServices.ReactivarService(null).subscribe();
    this.appService.listaNotificaciones = [];
    await this.clear();
    this.router.navigate(["/sign-in"]);
  }

  public async clear() {
   
      this.appService.Data.cartList.forEach(product => {
        this.appService.resetProductCartCount(product);
      });
      this.configuracionDatosBasicos.datos = {
        id_publico : null,
        nombres : '',
        direccion : '',
        celular : '',
        id_estado : 0,
        foto : null,
        calificacion : 0,
    } ;
      this.appService.Data.cartList.length = 0;
      this.appService.Data.totalPrice = 0;
      this.appService.Data.totalCartCount = 0;

  }

  public changeCurrency(currency) {
    let lista : any = this.ListaProveedores[0]
    let data : any = lista.filter(data => data.id == currency.id);
    this.nombreProv = data[0].nombre;
    this.sesionServices.reasignarItemAlmacen("tpoProductoBuscar" ,data[0].id )
    if(data[0].id == 1){
      location.href = "/#/fruvers"; 
    //  this.router.navigate(["fruvers"]) 
    }else{
      location.href = "/#/products"; 
    }
    location.reload();
  }

  public changeLang(flag) {
    this.flag = flag;
  }





}
