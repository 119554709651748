import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from 'src/app/interfaces/general/response'
import { ServicioPadre } from '../_general_/servicioPadre.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Proveedor } from 'src/app/interfaces/proveedor/Proveedor.interface';
@Injectable({
  providedIn: 'root'
})

export class ProveedoresService extends ServicioPadre {
  private HEADERS = new HttpHeaders().set('Content-Type', 'application/json');
  private TABLE = 'proveedor';

  constructor(private httpClient: HttpClient , public SnackBar:MatSnackBar ) {
    super(httpClient,SnackBar);
  }
 
  public async lista() : Promise<Response<Proveedor>> {
   return await this.PeticionGET(this.TABLE+'/index');
 }



 

}

