import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UbicacionService } from 'src/app/services/utils/ubicacion/ubicacion.service';
@Component({
  selector: 'app-mapas',
  templateUrl: './mapas.component.html',
  styleUrls: ['./mapas.component.scss']
})

export class MapasComponent implements OnInit {
  public pos = {long : 0 , lat : 0 , zoom : 20 , direccion : ''}
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogServices:MatDialog , public ubicacion: UbicacionService){}


  public obtenerDireccion(data) {
    this.ubicacion.ObtenerUbicacion(data, (lat, long) => {
      this.pos.lat = lat;
      this.pos.long = long;
    })
  }

  public placeMarker(event , ev){
    this.pos.lat = ev.coords.lat;
    this.pos.long = ev.coords.lng;
    this.ubicacion.ObtenerNombreUbicacion(this.pos.lat, this.pos.long, (name) => {
        this.pos.direccion = name.split(",")[0];
    });
  }

  public  confirmarUbicacion(){  
 
      this.data[0].cord.emit({coords:{
        lat :  this.pos.lat , lng : this.pos.long , direccion :  this.pos.direccion
      }});
      this.dialogServices.closeAll();
  
      document.getElementById("mat-input-6").click();
  }

  public  cancelarUbicacion(){
      this.data[0].cord.emit({coords:{
        lat : '' , lng : '' , direccion : ''
      }});
    this.dialogServices.closeAll();
    document.getElementById("mat-input-6").click();
  }

  

  ngOnInit(): void {
    this.pos.lat = this.data[0].lat;
    this.pos.long = this.data[0].long;
    this.pos.direccion = this.data[0].direccion;
  }

}
