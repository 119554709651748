import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, filter, switchMap  } from 'rxjs/operators';
import { RUTAS } from 'src/app/services/consts/rutas';
import { Response } from 'src/app/interfaces/general/response'
import { Observable } from 'rxjs';
import { SUPER_EXPR } from '@angular/compiler/src/output/output_ast';
import { ServicioPadre } from '../_general_/servicioPadre.service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Injectable({
  providedIn: 'root'
})

export class PersonasService extends ServicioPadre {
  private HEADERS = new HttpHeaders().set('Content-Type', 'application/json');
  private TABLE = 'persona';

  constructor(private httpClient: HttpClient , public SnackBar:MatSnackBar ) {
    super(httpClient,SnackBar);
  }

  public async Direcciones(DatosPersona) : Promise<Response<any>> {
    return await this.PeticionPOST(this.TABLE+'/direcciones' , DatosPersona);
  }

  public async EnviarPreguntas(DatosPersona) : Promise<Response<any>> {
    return await this.PeticionPOST(this.TABLE+'/EnviarPreguntas' , DatosPersona);
  }

  public async ActualizarDatosEnvio(DatosPersona) : Promise<Response<any>> {
    return await this.PeticionPOST(this.TABLE+'/ActualizarDatosEnvio' , DatosPersona);
  }

  public async ValidarIngresoDireccion(DatosPersona) : Promise<Response<any>> {
    return await this.PeticionPOST(this.TABLE+'/direccionesEnvio' , DatosPersona);
  }

  public async ValidarIngIdDireccion(DatosPersona) : Promise<Response<any>> {
    return await this.PeticionPOST(this.TABLE+'/idDireccion' , DatosPersona);
  }

  public async CambiarEstadoDireccion(DatosPersona) : Promise<Response<any>> {
    return await this.PeticionPUTDatos(this.TABLE+'/EliminarDirecciones' , DatosPersona);
  }

  public async detalleDireccion(DatosPersona) : Promise<Response<any>> {
    return await this.PeticionPOST(this.TABLE+'/detalleDireccion' , DatosPersona);
  }
  

  public async DatosPersona(DatosPersona) : Promise<Response<any>> {
    return await this.PeticionPOST(this.TABLE+'/datos' , DatosPersona);
  }

  public async VerificarSITieneEmpresa(DatosPersona) : Promise<Response<any>> {
    return await this.PeticionPOST(this.TABLE+'/tieneEmpresa' , DatosPersona);
  }

  public async CambiarContrasena(DatosPersona) : Promise<Response<any>> {
    return await this.PeticionPOST(this.TABLE+'/CambiarContrasena' , DatosPersona);
  }
 
  public async agregar(PersonaForm) : Promise<Response<any>> {
   return await this.PeticionPOST(this.TABLE+'/registrar' , PersonaForm);
 }

 public async Actualizar(PersonaForm) : Promise<Response<any>> {
  return await this.PeticionPUTDatos(this.TABLE+'/actualizar' , PersonaForm);
}



 

}

