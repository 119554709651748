import { Component, OnInit } from '@angular/core';
import { SesionService } from 'src/app/services/utils/general/general.service';
import { MatDialog } from '@angular/material/dialog';
import { ProveedoresService } from 'src/app/services/rest/proveedores/ProveedoresService';
import { Proveedor } from 'src/app/interfaces/proveedor/Proveedor.interface';

@Component({
  selector: 'app-tipos-productos',
  templateUrl: './tipos-productos.component.html',
  styleUrls: ['./tipos-productos.component.scss']
})
export class TiposProductosComponent implements OnInit {

  public config :any = {
    slidesPerView: 7,
    spaceBetween: 16,         
    keyboard: true,
    navigation: true,
    pagination: false,
    grabCursor: true,  
    loop: true,
    preloadImages: false,
    lazy: true,     
    autoplay: {
      delay: 6000,
      disableOnInteraction: false
    },
    speed: 500,
    effect: "slide"
  };
 public ListaProveedores:Proveedor[] = [];
  constructor(public sesion: SesionService,public dialog: MatDialog,public proveedorService : ProveedoresService) { }

  public SeleccionarTpoProd(tpo: number) {
    this.sesion.asignarItemAlmacen("tpoProductoBuscar", tpo);
    this.dialog.closeAll();
  }


  public async ListaTpoProveedores(){
    await this.proveedorService.lista().then((resp)=>{
      this.ListaProveedores.push(resp.datos)
     
    });
  }
  
  
 


  ngOnInit(): void {
    this.ListaTpoProveedores();
  }

}
