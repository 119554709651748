import { Component, OnInit, Input, Inject } from '@angular/core';
import { CompraService } from 'src/app/services/rest/compra/CompraService';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConstantesService } from 'src/app/services/rest/constantes/ConstantesService';

@Component({
  selector: 'app-detalles-compra',
  templateUrl: './detalles-compra.component.html',
  styleUrls: ['./detalles-compra.component.scss']
})
export class DetallesCompraComponent implements OnInit {
  @Input("id") _id : number;

  public listaProveedores = [];
  public listaCompras = [];
  public precioTotal = 0;
  public data = []; 
  constructor(@Inject(MAT_DIALOG_DATA) public datas: any,public consts: ConstantesService , public comprasService : CompraService){}



  public listaCompraFinal() {

   
    this.data.map(data => {
      if (this.listaProveedores.indexOf(data.aComprar) < 0) {
        this.listaProveedores.push(data.aComprar)
      }
    });

    this.listaProveedores.map((prov , i) => {
      this.listaCompras.push([])
      this.data.map(data => {
        if (prov == data.aComprar) {
          this.listaCompras[i].push(data)
        }
      });
    });

    this.listaCompras.map( (data , i )=> {
      this.listaCompras[i].total = 0;
      this.listaCompras[i].subtotal = 0;
      this.listaCompras[i].envio = 0;
      this.listaCompras[i].map(dat => {
        this.listaCompras[i].subtotal += dat.precio * dat.cantidad;
        if (dat.proveedor == "1") { // FRUVER
          this.consts.Obtener("KMS_FRUVER").then(kms => {
           
            this.listaCompras[i].envio += (parseFloat(dat.distancia) < parseFloat(dat.kmEnvioGratis)) ? 0 : ((dat.distancia * parseFloat(kms)))
            this.listaCompras[i].total += parseFloat((dat.precio * dat.cantidad).toString()) +   this.listaCompras[i].envio;
          });
        } else if (dat.proveedor == "2") {  // MAYORISTA
          this.consts.Obtener("PESO_MAYORISTA").then(peso => {
            this.consts.Obtener("KMS_MAYORISTA").then(kms => {
              this.listaCompras[i].envio += (parseFloat(dat.distancia) < parseFloat(dat.kmEnvioGratis)) ? 0 : (((parseFloat(dat.peso) * parseFloat(peso))) + (parseFloat(dat.distancia) * parseFloat(kms)))
              this.listaCompras[i].total += parseFloat((dat.precio * dat.cantidad).toString()) +   this.listaCompras[i].envio;
            });
          });
        } else { // AGRICULTRO
          this.consts.Obtener("PESO_AGRICULTOR").then(peso => {
            this.consts.Obtener("KMS_AGRICULTOR").then(kms => {
              this.listaCompras[i].envio += (parseFloat(dat.distancia) < parseFloat(dat.kmEnvioGratis)) ? 0 :  (((parseFloat(dat.peso) * parseFloat(peso))) + (parseFloat(dat.distancia) * parseFloat(kms)))
              this.listaCompras[i].total += parseFloat((dat.precio * dat.cantidad).toString()) +   this.listaCompras[i].envio;
            });
          });
        }
    
          
        setTimeout(()=>{
          this.precioTotal += parseFloat(this.listaCompras[i].total);
        },1000);
      })

    
    })
    
  }


  public  async ObtenerProductos(){
  
    await this.comprasService.Detalle({id : this.datas[0].id  }).then(resp => {
      this.data = resp.datos;
    }).then(()=>{
        this.listaCompraFinal();
    })
  }

  ngOnInit(): void {
    this.ObtenerProductos();
  }

}
