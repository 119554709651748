import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { RUTAS } from 'src/app/services/consts/rutas';
import { Response } from 'src/app/interfaces/general/response';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GENERAL } from '../../consts/general';
import { RUTAS_PRODUCCION } from '../../consts/rutasProduccion';

@Injectable()
export class ServicioPadre {
   private  header = new HttpHeaders({
    
  })


  public rutaRelativa = ( (GENERAL.MODO_DEBUG) ) ? RUTAS.URL.URL_BASE_BACKEND :  RUTAS_PRODUCCION.URL.URL_BASE_BACKEND;

  constructor(public HttpServices: HttpClient , public SnackBar:MatSnackBar) { }

  protected async PeticionGET(ruta: string): Promise<any> {
    return await this.HttpServices.get(this.rutaRelativa + ruta, { responseType: 'json', headers: this.header } ).toPromise()
    .then((resp:Response<any>)=>{
      this.ValidarEstados(resp.estado,resp.mensaje);
      return resp
    })
    .catch(err => {
      alert(JSON.stringify(err));
      this.SnackBar.open('Error en el servidor'+JSON.stringify(err), '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    });
  }

  protected async PeticionGETPorId(ruta: string , id): Promise<any> {
    return await this.HttpServices.get(this.rutaRelativa + ruta+`/${id}`, { responseType: 'json', headers: this.header } ).toPromise()
    .then((resp:Response<any>)=>{
      this.ValidarEstados(resp.estado,resp.mensaje);
      return resp
    })
    .catch(err => {
      alert(JSON.stringify(err));
      this.SnackBar.open('Error en el servidor', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    });
  }

  protected   PeticionPOST(ruta: string, objeto: JSON | Object) :  Promise<any> {
    return this.HttpServices.post(this.rutaRelativa +ruta, objeto, { responseType: 'json', headers: this.header }).toPromise()
    .then((resp:Response<any>)=>{
      this.ValidarEstados(resp.estado,resp.mensaje);
      return resp
    })
    .catch(err => {
      alert(JSON.stringify(err));
      this.SnackBar.open('Error en el servidor'+JSON.stringify(err), '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    });
  }

  private JsonParser(res: HttpResponse<Object>) {
    var array = new Array();
    var key, count = 0;
    for(key in res.body) {
        array.push(res.body[count++]);
    }
    return array;
  }

  private ValidarEstados(estado : number , mensaje){
    if(estado > 400 && estado < 500){
     this.SnackBar.open(mensaje, '×', { panelClass: 'warning', verticalPosition: 'top', duration: 3000 });
    }else if (estado > 500 && estado < 600){
      this.SnackBar.open(mensaje, '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    }else if (estado > 1000){
      this.SnackBar.open(mensaje, '×', { panelClass: 'warning', verticalPosition: 'top', duration: 3000 });
    }
    return;
  }

  protected async  PeticionDELETE(ruta: string , id:number): Promise<any> {
    return await this.HttpServices.delete(this.rutaRelativa +ruta+`/${id}`, { responseType: 'json', headers: this.header }).toPromise()
    .then((resp:Response<any>)=>{
      this.ValidarEstados(resp.estado,resp.mensaje);
      return resp
    })
    .catch(err => {
      alert(JSON.stringify(err));
      this.SnackBar.open('Error en el servidor'+JSON.stringify(err), '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    });
  }

  protected async PeticionPUT(ruta: string, id:number): Promise<any> {
    return await this.HttpServices.put(this.rutaRelativa + ruta+`/${id}`, { responseType: 'json', headers: this.header }).toPromise()
    .then((resp:Response<any>)=>{
      this.ValidarEstados(resp.estado,resp.mensaje);
      return resp
    })
    .catch(err => {
      alert(JSON.stringify(err));
      this.SnackBar.open('Error en el servidor'+JSON.stringify(err), '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    });
  }

  protected  PeticionPUTDatos(ruta: string, objeto: JSON | Object) :  Promise<any> {
    return this.HttpServices.put(this.rutaRelativa +ruta, objeto, { responseType: 'json', headers: this.header }).toPromise()
    .then((resp:Response<any>)=>{
      this.ValidarEstados(resp.estado,resp.mensaje);
      return resp
    })
    .catch(err => {
      alert(JSON.stringify(err));
      this.SnackBar.open('Error en el servidor'+JSON.stringify(err), '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    });
  }

}
