import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DecimalPipe } from '@angular/common';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { MatDialog } from '@angular/material/dialog';
import { ProductDialogComponent } from './product-dialog/product-dialog.component';
import { Data, AppService } from '../../app.service';
import { Product } from "../../app.models";
import { Settings, AppSettings, ConfiguracionDatosBasicos } from 'src/app/app.settings';
import { ProductoService } from 'src/app/services/rest/productos/ProductoService';
import { SesionService, GeneralServices } from 'src/app/services/utils/general/general.service';

@Component({
  selector: 'app-products-carousel',
  templateUrl: './products-carousel.component.html',
  styleUrls: ['./products-carousel.component.scss']
})
export class ProductsCarouselComponent implements OnInit {

  @Input('idProduct') product: any = {};
  public config: SwiperConfigInterface = {};
  public listaProducto : any[] = [];
  public settings: Settings;
  constructor(public appSettings:AppSettings, public appService:AppService, public dialog: MatDialog, private router: Router , public productoServicio : ProductoService , public configuracionDatosBasicos : ConfiguracionDatosBasicos , public sesionServicios : SesionService) { 
    this.settings = this.appSettings.settings;
  }

  public filtro:any = {
    idProducto : this.product,
    tpo_proveedor : this.sesionServicios.obtenerItemAlmacen("tpoProductoBuscar"),
    lat : this.appService.obtenerLatitud()  ,
    long : this.appService.obtenerLongitud()  
  };

  ngOnInit() { 
   
  }
  
  ngAfterViewInit(){
    this.config = {
      observer: true,
      slidesPerView: 1,
      spaceBetween: 16,       
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,        
      loop: false,
      preloadImages: false,
      lazy: true,  
      breakpoints: {
        480: {
          slidesPerView: 1
        },
        740: {
          slidesPerView: 2,
        },
        960: {
          slidesPerView: 4,
        },
        1280: {
          slidesPerView: 5,
        },
        1500: {
          slidesPerView: 6,
        }
      }
    }
  }

  public async listaFiltro(){
    await this.productoServicio.TodosProductosRelacionados({ filtro : this.filtro}).then( res => {
      this.listaProducto = res.datos;
    });
  }

  public openProductDialog(product){   
    let dialogRef = this.dialog.open(ProductDialogComponent, {
        data: product,
        panelClass: 'product-dialog',
        direction: (this.settings.rtl) ? 'rtl' : 'ltr'
    });
    dialogRef.afterClosed().subscribe(product => {
      if(product){
        this.router.navigate(['/products', product.id, product.name]); 
      }
    });
  }

  LimpiarRuta(value){
    return GeneralServices.LimpiarRuta(value);
  }

  ngOnChanges(){
    this.filtro.idProducto = this.product.id;
    this.listaFiltro();
  }

}