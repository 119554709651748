import { Component, ViewEncapsulation, OnInit, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Data, AppService } from '../../../app.service';
import { Product } from '../../../app.models';
import { ProductoService } from 'src/app/services/rest/productos/ProductoService';
import { ConfiguracionDatosBasicos } from 'src/app/app.settings';
import { CarritoService } from 'src/app/services/rest/carrito/CarritoService';
import { SesionService } from 'src/app/services/utils/general/general.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-product-dialog',
  templateUrl: './product-dialog.component.html',
  styleUrls: ['./product-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductDialogComponent implements OnInit {
  public config: SwiperConfigInterface = {};
  public product: any;
  public image: any;
  public zoomImage: any;
  constructor(public appService:AppService, 
              public dialogRef: MatDialogRef<ProductDialogComponent>,
              public configuracionDatosBasicos: ConfiguracionDatosBasicos,
               public carritoServices: CarritoService, 
              private productoServices: ProductoService,
               public sesionServices: SesionService,
               public snackBar: MatSnackBar, 
              @Inject(MAT_DIALOG_DATA) public productData: any) { }

  ngOnInit() { 
      this.getProductById(this.productData.id);
  }

  public async getProductById(id) {
    await this.productoServices.ProductoDetalle({ id: id }).then(data => {
      this.product = data.datos;
      this.image = data.datos.images[0].medium;
      this.zoomImage = data.datos.images[0].big;
      setTimeout(() => {
        this.config.observer = true;
        // this.directiveRef.setIndex(0);
      });
    });
  }

  public GestionarTipo(product, i, tipo) {

    if (tipo == '-') {
      product.presentaciones[i].cantidades -= (product.presentaciones[i].cantidades != 0) ? 1 : 0;
    } else {
      product.presentaciones[i].cantidades += (product.presentaciones[i].cantidades != product.presentaciones[i].stock) ? 1 : 0;
    }
  }


  public async Carrrito(product) {
    product.presentaciones.map(async presentacion => {
      if (presentacion.cantidades != 0) {
        await this.carritoServices.Insertar({ form: presentacion, persona: this.configuracionDatosBasicos.datos }).then(resp => {
          if (resp.estado == 200) {
            let datos = JSON.parse(this.sesionServices.obtenerItemAlmacen("__u0s1e2r3@__"));
            datos.carrito.push(resp.datos);
            this.sesionServices.reasignarItemAlmacen("__u0s1e2r3@__", JSON.stringify(datos));
            this.appService.actualizarDatosCarrito();
            this.snackBar.open('Se agrego el producto satisfactoriamente.', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
            this.dialogRef.close();
          }
        });
      }
    });
  }

  ngAfterViewInit(){
    this.config = {
      slidesPerView: 1,
      spaceBetween: 0,         
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,        
      loop: false,
      preloadImages: false,
      lazy: true, 
      effect: "fade",
      fadeEffect: {
        crossFade: true
      }
    }
  }

  public close(): void {
    this.dialogRef.close();
  }
}