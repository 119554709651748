import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CiudadService } from 'src/app/services/rest/ciudades/CiudadService';
import { ProductDialogComponent } from 'src/app/shared/products-carousel/product-dialog/product-dialog.component';
import { FruversComponent } from '../../fruvers/fruvers.component';

@Component({
  selector: 'app-lista-ciudades',
  templateUrl: './lista-ciudades.component.html',
  styleUrls: ['./lista-ciudades.component.scss']
})
export class ListaCiudadesComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any , public ciudadesServices:CiudadService , public dialogServices: MatDialogRef<ProductDialogComponent> ){}
  public departSel : boolean = false;
  public ciudades : any[] = [];
  public municipios : any[] = [];

  @Input() categoryActual?:any;
  
  @Output() change: EventEmitter<any> = new EventEmitter();

  public async ListaDepCiudades(){
    await this.ciudadesServices.ListaDepartamentosCiudad().then((res)=>{
        this.ciudades = res.datos;
    });
  }


  public seleccionarDepartamento(dep){
    this.departSel = true;
    this.municipios = dep.ciudades
  }

  public agregarCiudad(mun){
    
    console.log("ciudad");
    
    this.data[0].getData.emit(mun.nombre);
    this.dialogServices.close({data : mun.nombre });
  
    // document.getElementById("mat-input-6").click();
  }

  public regresarDepartamento(){
    this.departSel = false;
  }

  public stopClickPropagate(event: any){
    if(window.innerWidth < 960){
      event.stopPropagation();
      event.preventDefault();
    }    
  }

  public changeCategory(event){
    this.change.emit(event);
  }

  ngOnInit(){
      this.ListaDepCiudades(); 
  }

}
