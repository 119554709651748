import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Settings, AppSettings, ConfiguracionDatosBasicos } from './app.settings';
import { GENERAL } from './services/consts/general';
import { RUTAS } from './services/consts/rutas';
import { RUTAS_PRODUCCION } from './services/consts/rutasProduccion';
import { UbicacionService } from './services/utils/ubicacion/ubicacion.service';
import { SesionService } from './services/utils/general/general.service';
import { UsuariosService } from './services/rest/usuarios/UsuariosService';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  loading: boolean = false;
  public settings: Settings;
  constructor(public appSettings: AppSettings, public router: Router, public ubicacionService: UbicacionService, public sesionService: SesionService, public usuarioServices: UsuariosService, public configuracionDatosBasicos: ConfiguracionDatosBasicos) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    if (!GENERAL.MODO_DEBUG) {
      RUTAS.GENERAL = RUTAS_PRODUCCION.GENERAL;
      RUTAS.URL = RUTAS_PRODUCCION.URL;
      RUTAS.FOLDERS = RUTAS_PRODUCCION.FOLDERS;
      RUTAS.PROPIEDAD_WEB = RUTAS_PRODUCCION.PROPIEDAD_WEB;
    }
      this.SolicitarGPS();
  }

  public SolicitarGPS() {
    let gps = JSON.parse(this.sesionService.obtenerItemAlmacen("__gps_data__"));
    if (gps == null || gps == undefined) {
      this.ubicacionService.ValidarGPSPermisos((pos) => {
        this.sesionService.asignarItemAlmacen("__gps_data__", JSON.stringify({ pos_x: pos.coords.latitude, pos_y: pos.coords.longitude }));
        if (this.sesionService.obtenerItemAlmacen("__u0s1e2r3@__") !== null) {
          this.configuracionDatosBasicos.datos = JSON.parse(this.sesionService.obtenerItemAlmacen("__u0s1e2r3@__"));
          this.usuarioServices.ActualizarPosicion({ pos: { pos_lat: pos.coords.latitude, pos_lon: pos.coords.longitude }, persona: this.configuracionDatosBasicos.datos })
        }
      });
    }
  }

  ngAfterViewInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
        
      }
    })
  }
}
