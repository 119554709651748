import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { Router, NavigationEnd, Params } from '@angular/router';
import { Settings, AppSettings, ConfiguracionDatosBasicos } from '../app.settings';
import { AppService } from '../app.service';
import { Category, Product } from '../app.models';
import { SidenavMenuService } from '../theme/components/sidenav-menu/sidenav-menu.service';
import { CategoriasService } from '../services/rest/categorias/CategoriaService';
import { SesionService, GeneralServices } from '../services/utils/general/general.service';
import { CarritoService } from '../services/rest/carrito/CarritoService';
import { NotificacionesService } from '../services/rest/notificaciones/NotificacionesService';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  providers: [SidenavMenuService]
})
export class PagesComponent implements OnInit {
  public showBackToTop: boolean = false;
  public categories: any[] = [];
  public category: Category;
  public categoriaMostrar = 'Seleccione una categoria';
  public sidenavMenuItems: Array<any>;
  @ViewChild('sidenav', { static: true }) sidenav: any;

  public settings: Settings;
  constructor(public appSettings: AppSettings,
    public appService: AppService,
    public sidenavMenuService: SidenavMenuService,
    public router: Router,
    public categoriaServices: CategoriasService,
    public carritoServices: CarritoService,
    public sesionServices: SesionService,
    public notificacionseServices :NotificacionesService,
    public configuracionDatosBasicos: ConfiguracionDatosBasicos
  ) {
    this.settings = this.appSettings.settings;
    this.appService.actualizarDatosCarrito();
  }

  ngOnInit() {
    this.getCategories();
    this.sidenavMenuItems = this.sidenavMenuService.getSidenavMenuItems();
  }

  public async getCategories() {
    await this.categoriaServices.CategoriasYSubcategoria().then(resp => {
      this.categories = resp.datos;
      this.category = resp.datos[0];
    });
  }


  public visto(lista , i ){
    this.notificacionseServices.Visto({id : lista.id}).then(res => {
        this.appService.listaNotificaciones.splice(i , 1 )
    });
  } 

  public changeCategory(event) {
    if (event.target) {
      this.categoriaMostrar = event.target.innerText;
    }
    if (window.innerWidth < 960) {
      this.stopClickPropagate(event);
    }
  }

  public async remove(product) {
    await this.carritoServices.Eliminar({ id: product.id }).then((res) => {
      const index: number = this.appService.Data.cartList.indexOf(product);
      this.appService.Data.cartList.splice(index, 1);
      if (index !== -1) {
        let datos = JSON.parse(this.sesionServices.obtenerItemAlmacen("__u0s1e2r3@__"));
        datos.carrito.splice(index, 1);
        this.sesionServices.reasignarItemAlmacen("__u0s1e2r3@__", JSON.stringify(datos));
        this.appService.Data.totalPrice = this.appService.Data.totalPrice - product.precio;
        this.appService.Data.totalCartCount = this.appService.Data.totalCartCount - 1;
        this.appService.resetProductCartCount(product);
      }
    });

  }

  public async clear() {
    await this.carritoServices.EliminarTodo({ persona: this.configuracionDatosBasicos.datos }).then((res) => {
      this.appService.Data.cartList.forEach(product => {
        this.appService.resetProductCartCount(product);
      });
      let datos = JSON.parse(this.sesionServices.obtenerItemAlmacen("__u0s1e2r3@__"));
      datos.carrito = [];
      this.sesionServices.reasignarItemAlmacen("__u0s1e2r3@__", JSON.stringify(datos));

      this.appService.Data.cartList.length = 0;
      this.appService.Data.totalPrice = 0;
      this.appService.Data.totalCartCount = 0;

    });
  }


  public changeTheme(theme) {
    this.settings.theme = theme;
  }

  public stopClickPropagate(event: any) {
    event.stopPropagation();
    event.preventDefault();
  }

  LimpiarRuta(value) {
    return GeneralServices.LimpiarRuta(value);
  }

  public search(search) {
    let sesion = this.sesionServices.obtenerItemAlmacen("tpoProductoBuscar")
    if (search.value != '') {
      if(sesion !== "Fruver"){
        this.router.navigate(["products/" + this.LimpiarRuta(this.categoriaMostrar) + "/search/" + this.LimpiarRuta(search.value)]);
      }else{
        this.router.navigate(["fruvers/search/" + this.LimpiarRuta(search.value)]);
      }
     
    }
  }


  public scrollToTop() {
    var scrollDuration = 200;
    var scrollStep = -window.pageYOffset / (scrollDuration / 20);
    var scrollInterval = setInterval(() => {
      if (window.pageYOffset != 0) {
        window.scrollBy(0, scrollStep);
      }
      else {
        clearInterval(scrollInterval);
      }
    }, 10);
    if (window.innerWidth <= 768) {
      setTimeout(() => { window.scrollTo(0, 0) });
    }
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    ($event.target.documentElement.scrollTop > 300) ? this.showBackToTop = true : this.showBackToTop = false;
  }

  ngAfterViewInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.sidenav.close();
      }
    });
    this.sidenavMenuService.expandActiveSubMenu(this.sidenavMenuService.getSidenavMenuItems());
  }

  public closeSubMenus() {
    if (window.innerWidth < 960) {
      this.sidenavMenuService.closeAllSubMenus();
    }
    this.router.navigate(["/"])
  }

}