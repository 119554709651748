import { Injectable } from '@angular/core';
import { callbackify } from 'util';
import { ReadVarExpr } from '@angular/compiler';
import { MapsAPILoader } from '@agm/core';
import { MatSnackBar } from '@angular/material/snack-bar';
declare var google; 
@Injectable({
  providedIn: 'root'
})

export class UbicacionService {

  constructor(private mapsAPILoader: MapsAPILoader, public snackBar: MatSnackBar) { }

  public ValidarGPSPermisos(callback) {
    if (navigator.geolocation) {
      var success = function (position) {
        callback(position)
      }
      navigator.geolocation.getCurrentPosition(success, function (msg) {
        console.error(msg);
      });
    }
  }

  public ObtenerUbicacion(direccion: string, callback) {
    this.mapsAPILoader.load().then(() => {
      let geocoder = new google.maps.Geocoder();
      geocoder.geocode({ 'address': direccion }, function (results, status) {
        if (status == google.maps.GeocoderStatus.OK) {
          callback(results[0].geometry.location.lat(), results[0].geometry.location.lng());
        } else {
          alert('No se encontro Ubicación!');
        }
      });
    });
  }

  public ObtenerNombreUbicacion(lat , lon , callback) {
    const latlng = {
      lat: parseFloat(lat),
      lng: parseFloat(lon)
    };
    this.mapsAPILoader.load().then(() => {
      let geocoder = new google.maps.Geocoder();
      geocoder.geocode({ 'location': latlng }, function (results, status) {
        if (status == google.maps.GeocoderStatus.OK) {
          if (results[0]) {
           callback(results[0].formatted_address);
          }
        }
      });
    });
  }



}
