import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, filter, switchMap  } from 'rxjs/operators';
import { RUTAS } from 'src/app/services/consts/rutas';
import { Response } from 'src/app/interfaces/general/response'
import { Observable } from 'rxjs';
import { SUPER_EXPR } from '@angular/compiler/src/output/output_ast';
import { ServicioPadre } from '../_general_/servicioPadre.service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Injectable({
  providedIn: 'root'
})

export class CiudadService extends ServicioPadre {
  private HEADERS = new HttpHeaders().set('Content-Type', 'application/json');
  private TABLE = 'ciudad';

  constructor(private httpClient: HttpClient , public SnackBar:MatSnackBar ) {
    super(httpClient,SnackBar);
  }


  public async Departamentos() : Promise<Response<any>> {
    return await this.PeticionGET(this.TABLE+'/departamentos' );
  }


  public async ListaCiudad(Departamento) : Promise<Response<any>> {
    return await this.PeticionPOST(this.TABLE+'/ciudad', Departamento );
  }


  public async ListaDepartamentosCiudad() : Promise<Response<any>> {
    return await this.PeticionGET(this.TABLE+'/DepartamentosCiudades' );
  }


  
 
 

 



 

}

