import { Component, OnInit } from '@angular/core';
import { SesionService } from 'src/app/services/utils/general/general.service';
import { MatDialog } from '@angular/material/dialog';
import { ProveedoresService } from 'src/app/services/rest/proveedores/ProveedoresService';
import { Proveedor } from 'src/app/interfaces/proveedor/Proveedor.interface';
import { PersonasService } from 'src/app/services/rest/personas/PersonasService';

@Component({
  selector: 'app-recuperar-contrasena',
  templateUrl: './recuperar-contrasena.component.html',
  styleUrls: ['./recuperar-contrasena.component.scss']
})
export class RecuperarContrasenaComponent implements OnInit {

  constructor(public sesion: SesionService,public dialog: MatDialog,public proveedorService : ProveedoresService , public personaServices : PersonasService) { }

  public SeleccionarTpoProd(tpo: number) {
    this.sesion.asignarItemAlmacen("tpoProductoBuscar", tpo);
    this.dialog.closeAll();
  }


 async recuperarContrasena(correo){
   
      await this.personaServices.CambiarContrasena({correo :   correo.value }).then(res => {
        this.dialog.closeAll();
      })
  }

  ngOnInit(): void {
  
  }

}
