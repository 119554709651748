export class RUTAS_PRODUCCION{
    public static PROPIEDAD_WEB = {
        PROTOCOLO: "http://",
        DOMINIO: "agroapp.co",
        PUERTO: "",
        BACKEND: "/api/",
        FRONTEND: "/public/",
    }

    public static FOLDERS = {
        PUBLIC: "public/",
        PRODUCTO: "producto/",
        API: "api/",
        STORAGE: "src/",
        IMAGENES: "img/",

    }

    public static URL = {

        /*  ejemplo : http:localhost:80/agroapp/api/ */
        URL_BASE_BACKEND: RUTAS_PRODUCCION.PROPIEDAD_WEB.PROTOCOLO + RUTAS_PRODUCCION.PROPIEDAD_WEB.DOMINIO + RUTAS_PRODUCCION.PROPIEDAD_WEB.PUERTO + RUTAS_PRODUCCION.PROPIEDAD_WEB.BACKEND,
        /*  ejemplo : http:localhost:80/agroapp/public/ */
        URL_BASE_FRONTEND: RUTAS_PRODUCCION.PROPIEDAD_WEB.PROTOCOLO + RUTAS_PRODUCCION.PROPIEDAD_WEB.DOMINIO + RUTAS_PRODUCCION.PROPIEDAD_WEB.PUERTO + RUTAS_PRODUCCION.PROPIEDAD_WEB.FRONTEND,


    }


    public static GENERAL = {
        /*  ejemplo : http:localhost:80/agroapp/public/src/ */
        FOLDER_BASE_IMAGENES: RUTAS_PRODUCCION.URL.URL_BASE_BACKEND + RUTAS_PRODUCCION.FOLDERS.STORAGE,
    }


}
