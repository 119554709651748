import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, filter, switchMap  } from 'rxjs/operators';
import { RUTAS } from 'src/app/services/consts/rutas';
import { Response } from 'src/app/interfaces/general/response'
import { Observable } from 'rxjs';
import { SUPER_EXPR } from '@angular/compiler/src/output/output_ast';
import { ServicioPadre } from '../_general_/servicioPadre.service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Injectable({
  providedIn: 'root'
})

export class UsuariosService extends ServicioPadre {
  private HEADERS = new HttpHeaders().set('Content-Type', 'application/json');
  private TABLE = 'usuario';

  constructor(private httpClient: HttpClient , public SnackBar:MatSnackBar ) {
    super(httpClient,SnackBar);
  }


  public async ActualizarPosicion(UsuarioForm) : Promise<Response<any>> {
    return await this.PeticionPUTDatos(this.TABLE+'/posicion' , UsuarioForm);
  }
 
  public async ActualizarFoto(UsuarioForm) : Promise<Response<any>> {
    return await this.PeticionPUTDatos(this.TABLE+'/foto' , UsuarioForm);
  }
 
  public async autenticar(UsuarioForm) : Promise<Response<any>> {
   return await this.PeticionPOST(this.TABLE+'/autenticar' , UsuarioForm);
 }


 public async CambiarContrasena(passwordForm) : Promise<Response<any>> {
  return await this.PeticionPUTDatos(this.TABLE+'/cambiarContrasena' , passwordForm);
}



public async CambiarContrasenaRap(passwordForm) : Promise<Response<any>> {
  return await this.PeticionPUTDatos(this.TABLE+'/cambiarContrasenaRap' , passwordForm);
}

 



 

}

