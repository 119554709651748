import { Injectable } from '@angular/core';
import { GENERAL } from './services/consts/general';
import { PersonaBasico } from './interfaces/persona/Persona.interface';

export class Settings {
    constructor(public name: string,
                public theme: string,
                public rtl: boolean) { }
}

@Injectable()
export class AppSettings {
    public settings = new Settings(
        GENERAL.NOMBRE_PROYECTO ,  // theme name
        GENERAL.COLOR ,     // green, blue, red, pink, purple, grey
        false       // true = rtl, false = ltr
    )
}



export class ConfiguracionDatosBasicos {
    public datos :any = {
        id_publico : null,
        nombres : '',
        direccion : '',
        celular : '',
        id_estado : 0,
        foto : null,
        calificacion : 0,
    } ;

    public TieneSesion(){
        if(this.datos.id_publico === null && this.datos.nombres === '' ){
            return false
        }
        return true;
    }
}

