import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ListaCiudadesComponent } from 'src/app/pages/modals/lista-ciudades/lista-ciudades.component';
import { CiudadService } from 'src/app/services/rest/ciudades/CiudadService';

@Component({
  selector: 'app-city-list',
  templateUrl: './city-list.component.html',
  styleUrls: ['./city-list.component.scss']
})
export class CityListComponent {

  public ciudadEmitter = new EventEmitter();

  constructor(public ciudadesServices: CiudadService, public dialog: MatDialog) {
    this.ciudadEmitter.subscribe((data) => {
      this.change.emit(data);
    })
  }



  public ciudades: any = [];
  public ciudadesCopia: any[] = [];
  public ciudadesCopiaUnica: any[] = [];
  
  @Input() categoryActual?: any;
  @Output() change: EventEmitter<any> = new EventEmitter();

  public async ListaDepCiudades() {
    await this.ciudadesServices.ListaDepartamentosCiudad().then((res) => {
      this.ciudades = res.datos;
      this.ciudadesCopia = res.datos;
      this.ciudadesCopiaUnica = res.datos;
      
    });
  }

  public desplegarMapa() {
    this.dialog.open(ListaCiudadesComponent, {
      width: '100%',
      //  height : '100vh',
      disableClose: true,
      data: [{
        getData: this.ciudadEmitter
      }]
    });
  }

  public filtrarCiudad(valor) {
    if(valor.value.length == 0){
      this.ListaDepCiudades();
      return;
    }
    valor.value = valor.value.toLowerCase();
    this.ciudades = [...this.ciudadesCopia];
    const ciudadesFiltradas = this.ciudades.map((ciudad) => ciudad.ciudades.filter((ciudadFilter => ciudadFilter.nombre.toLowerCase().match(valor.value))
    ))
    this.ciudades = [];
    ciudadesFiltradas.map((ciudad , index)=>{
      if(ciudad.length > 0){
        let ciudadTemp =  this.ciudadesCopia[index]
        ciudadTemp.ciudades = ciudadesFiltradas[index];
        this.ciudades.push(this.ciudadesCopia[index]);
      }
    })
  }

  public stopClickPropagate(event: any) {
    if (window.innerWidth < 960) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  public changeCategory(event) {

    this.change.emit(event);
  }

  ngOnInit() {
    //alert();
    this.ListaDepCiudades();
  }

}