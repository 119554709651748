import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AgmCoreModule } from '@agm/core';

import { OverlayContainer, Overlay } from '@angular/cdk/overlay';
import { MAT_MENU_SCROLL_STRATEGY } from '@angular/material/menu';
import { CustomOverlayContainer } from './theme/utils/custom-overlay-container';
import { menuScrollStrategy } from './theme/utils/scroll-strategy';

import { SharedModule } from './shared/shared.module';
import { routing } from './app.routing';
import { AppComponent } from './app.component';
import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { TopMenuComponent } from './theme/components/top-menu/top-menu.component';
import { MenuComponent } from './theme/components/menu/menu.component';
import { SidenavMenuComponent } from './theme/components/sidenav-menu/sidenav-menu.component';
import { BreadcrumbComponent } from './theme/components/breadcrumb/breadcrumb.component';

import { AppSettings, ConfiguracionDatosBasicos } from './app.settings';
import { AppService } from './app.service';
import { AppInterceptor } from './theme/utils/app-interceptor';
import { OptionsComponent } from './theme/components/options/options.component';
import { FooterComponent } from './theme/components/footer/footer.component';
import {LocationStrategy, HashLocationStrategy} from '@angular/common';
import { TiposProductosComponent } from './pages/modals/tipos-productos/tipos-productos.component';
import { SesionService } from './services/utils/general/general.service';
import { SignInServices } from './pages/sign-in/sign-in.services';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { AgregarProductosComponent } from './pages/product-account/products/modals/agregar-productos/agregar-productos.component';
import { MatChipsModule } from '@angular/material/chips';
import { DetallesCompraComponent } from './pages/detalles-compra/detalles-compra.component';
import { DetallesCompraModule } from './pages/detalles-compra/detalles-compra.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from './pages/modals/modals.module';
import { InViewportModule } from 'ng-in-viewport';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { IImageLoaderOptions, NgxProgressiveImageLoaderModule } from 'ngx-progressive-image-loader';

@NgModule({

   imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,    
    ModalModule,
    ReactiveFormsModule,  
    InViewportModule,
    SwiperModule,
    NgxSpinnerModule,
   
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAlxlP2PiwR4S_w34kDAHe0CU43sfa05eg'
    }),
    SharedModule,
    DetallesCompraModule,
    routing,
    MatChipsModule
  ],
  declarations: [
    AppComponent,
    PagesComponent,
   
    NotFoundComponent,
    TopMenuComponent,
    MenuComponent,
    SidenavMenuComponent,
    BreadcrumbComponent,
    OptionsComponent,
    FooterComponent,
 
  ], 
  providers: [
    AppSettings,
    AppService,   
    SesionService,
    SignInServices,
    ConfiguracionDatosBasicos,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    { provide: OverlayContainer, useClass: CustomOverlayContainer },
    { provide: MAT_MENU_SCROLL_STRATEGY, useFactory: menuScrollStrategy, deps: [Overlay] },
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }