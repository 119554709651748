import { Pipe, PipeTransform } from '@angular/core';
import { isArray } from 'util';

@Pipe({
  name: 'filterById'
})
export class FilterByIdPipe implements PipeTransform {

  transform(items:Array<any>, id?) {
    if( !isArray(items) ){
      return  []
    }
    return items.filter(item => item.id == id)[0];
  }
}