import { Component, OnInit } from '@angular/core';
import { ConfiguracionDatosBasicos } from 'src/app/app.settings';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public lat: number =  4.570868;
  public lng: number = -74.297333;
  public zoom: number = 5;
  public usuarioVo : ConfiguracionDatosBasicos;

  constructor(private usuario : ConfiguracionDatosBasicos ) { 
    this.usuarioVo = usuario;
  }

  ngOnInit() { }

  subscribe(){ }

}