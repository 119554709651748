import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, filter, switchMap  } from 'rxjs/operators';
import { Response } from 'src/app/interfaces/general/response'
import { ServicioPadre } from '../_general_/servicioPadre.service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Injectable({
  providedIn: 'root'
})

export class CompraService extends ServicioPadre {
  private HEADERS = new HttpHeaders().set('Content-Type', 'application/json');
  private TABLE = 'compra';

  constructor(private httpClient: HttpClient , public SnackBar:MatSnackBar ) {
    super(httpClient,SnackBar);
  }

  public async ListaEstadoCompra(CompraForm) : Promise<Response<any>> {
    return await this.PeticionPOST(this.TABLE+'/ListaEstadoCompra' , CompraForm);
  }

  public async EntregaPedido(CompraForm) : Promise<Response<any>> {
    return await this.PeticionPOST (this.TABLE+'/EntregaPedido' , CompraForm);
  }
  
  public async ActualizarEstado(CompraForm) : Promise<Response<any>> {
    return await this.PeticionPUTDatos(this.TABLE+'/ActualizarEstado' , CompraForm);
  }

  public async RealizarCompra(CompraForm) : Promise<Response<any>> {
    return await this.PeticionPOST(this.TABLE+'/Comprar' , CompraForm);
  }

  public async ListaComprasServ(CompraForm) : Promise<Response<any>> {
    return await this.PeticionPOST(this.TABLE+'/ListaComprasServ' , CompraForm);
  }

  public async Detalle(CompraForm) : Promise<Response<any>> {
    return await this.PeticionPOST(this.TABLE+'/Detalles' , CompraForm);
  }

  public async ListaVentasServ(CompraForm) : Promise<Response<any>> {
    return await this.PeticionPOST(this.TABLE+'/ListaVentasServ' , CompraForm);
  }

 

}

