import { Component, OnInit, Input } from '@angular/core';
import { SidenavMenuService } from './sidenav-menu.service';
import { CategoriasService } from 'src/app/services/rest/categorias/CategoriaService';

@Component({
  selector: 'app-sidenav-menu',
  templateUrl: './sidenav-menu.component.html',
  styleUrls: ['./sidenav-menu.component.scss'],
  providers: [ SidenavMenuService ]
})
export class SidenavMenuComponent implements OnInit {
  @Input('menuItems') menuItems;
  @Input('menuParentId') menuParentId;
  parentMenu:Array<any>;
  public categorias:any[] = [];
  constructor(private sidenavMenuService:SidenavMenuService,public categoriaServices :CategoriasService ) { }

  ngOnInit() {
    this.parentMenu = this.menuItems.filter(item => item.parentId == this.menuParentId);
    this.Categorias();
  }

  public async Categorias(){
    await this.categoriaServices.TodasCategorias().then(resp => {
      this.categorias = resp.datos;
    });
  }

}
