import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { NgxPaginationModule, PaginatePipe } from 'ngx-pagination';
import { SharedModule } from 'src/app/shared/shared.module';
import { PipesModule } from 'src/app/theme/pipes/pipes.module';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PresentationsComponent } from './presentations.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';


@NgModule({
  declarations : [
    PresentationsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    SwiperModule,
    NgxPaginationModule,
    //SharedModule,
    MatIconModule,
    PipesModule,
    MatButtonModule,
    PipesModule,
    NgxPaginationModule,
    MatInputModule,
    MatFormFieldModule
  ],
  exports:[
    PresentationsComponent
  ]
})
export class PresentationModule { }
