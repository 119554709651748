export class RUTAS {
    public static PROPIEDAD_WEB = {
        PROTOCOLO: "http://",
        DOMINIO: "localhost",
        PUERTO: "",
        BACKEND: "/agroapp/api/",
        FRONTEND: "/agroapp/public/",
    }

    public static FOLDERS = {
        PUBLIC: "public/",
        PRODUCTO: "producto/",
        API: "api/",
        STORAGE: "src/",
        IMAGENES: "img/",

    }

    public static URL = {

        /*  ejemplo : http:localhost:80/agroapp/api/ */
        URL_BASE_BACKEND: RUTAS.PROPIEDAD_WEB.PROTOCOLO + RUTAS.PROPIEDAD_WEB.DOMINIO + RUTAS.PROPIEDAD_WEB.PUERTO + RUTAS.PROPIEDAD_WEB.BACKEND,
        /*  ejemplo : http:localhost:80/agroapp/public/ */
        URL_BASE_FRONTEND: RUTAS.PROPIEDAD_WEB.PROTOCOLO + RUTAS.PROPIEDAD_WEB.DOMINIO + RUTAS.PROPIEDAD_WEB.PUERTO + RUTAS.PROPIEDAD_WEB.FRONTEND,


    }


    public static GENERAL = {
        /*  ejemplo : http:localhost:80/agroapp/public/src/ */
        FOLDER_BASE_IMAGENES: RUTAS.URL.URL_BASE_BACKEND + RUTAS.FOLDERS.STORAGE,
    }


}
