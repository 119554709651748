import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Category, Product } from './app.models';
import { SesionService } from './services/utils/general/general.service';
import { ConfiguracionDatosBasicos } from './app.settings';

export class Data {
    constructor(public categories: Category[],
                public compareList: Product[],
                public wishList: Product[],
                public cartList: any[] = [],
                public totalPrice: number,
                public totalCartCount: number,
                public sesionServices:SesionService
                ) {}
}

@Injectable()
export class AppService {
    public listaNotificaciones = []
    public Data = new Data(
        [], // categories
        [], // compareList
        [],  // wishList
        [],  // cartList
        null, //totalPrice,
        0, //totalCartCount,
        null
    )
    public url = "assets/data/";
    constructor(public http:HttpClient, public snackBar: MatSnackBar,    public sesionServices:SesionService , private configuracionDatosBasicos : ConfiguracionDatosBasicos) {
        this.actualizarDatosCarrito();
    }
    
    public obtenerLatitud() : number{
        if(JSON.parse(this.sesionServices.obtenerItemAlmacen("__gps_data__"))  != undefined || JSON.parse(this.sesionServices.obtenerItemAlmacen("__gps_data__"))  != null ){
            console.log(JSON.parse(this.sesionServices.obtenerItemAlmacen("__gps_data__")));
            
            return JSON.parse(this.sesionServices.obtenerItemAlmacen("__gps_data__"))["pos_x"];
        }
        if(this.configuracionDatosBasicos.datos.pos_lat  !== undefined){
            return this.configuracionDatosBasicos.datos.pos_lat;
        } 
        return 0;
     } 
     
     public obtenerLongitud() : number{
        if(JSON.parse(this.sesionServices.obtenerItemAlmacen("__gps_data__"))  != undefined || JSON.parse(this.sesionServices.obtenerItemAlmacen("__gps_data__"))  != null){
            return JSON.parse(this.sesionServices.obtenerItemAlmacen("__gps_data__"))["pos_y"];
        } 
        if(this.configuracionDatosBasicos.datos.pos_lon  !== undefined){
            return this.configuracionDatosBasicos.datos.pos_lon;
        } 
        return 0;
     }

     public actualizarDatosCarrito(){
        this.Data.totalPrice = 0;
        let data = JSON.parse(this.sesionServices.obtenerItemAlmacen("__u0s1e2r3@__"));
        if(data != null || data != undefined ){
            this.Data.cartList = data.carrito;
            this.Data.totalCartCount = data.carrito.length;
           
            
           data.carrito.map(data => {
            this.Data.totalPrice +=  parseFloat(data.precio) * data.cantidad  ;
            });
            
        }
     }
    
    public getCategories(): Observable<Category[]>{
        return this.http.get<Category[]>(this.url + 'categories.json');
    }
   
    public getProducts(type): Observable<Product[]>{        
        return this.http.get<Product[]>(this.url + type + '-products.json');
    }

    public getProductById(id): Observable<Product>{
        return this.http.get<Product>(this.url + 'product-' + id + '.json');
    }

    public getBanners(): Observable<any[]>{
        return this.http.get<any[]>(this.url + 'banners.json');
    }

    public addToCompare(product:Product){
        let message, status;
        if(this.Data.compareList.filter(item=>item.id == product.id)[0]){
            message = 'The product ' + product.name + ' already added to comparison list.'; 
            status = 'error';     
        }
        else{
            this.Data.compareList.push(product);
            message = 'The product ' + product.name + ' has been added to comparison list.'; 
            status = 'success';  
        }
        this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });
    }

    public addToWishList(product:Product){
        let message, status;
        if(this.Data.wishList.filter(item=>item.id == product.id)[0]){
            message = 'The product ' + product.name + ' already added to wish list.'; 
            status = 'error';     
        }
        else{
            this.Data.wishList.push(product);
            message = 'The product ' + product.name + ' has been added to wish list.'; 
            status = 'success';  
        }
        this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });
    } 

    public addToCart(product:Product){
        let message, status;        
       
        this.Data.totalPrice = null;
        this.Data.totalCartCount = null;

        if(this.Data.cartList.filter(item=>item.id == product.id)[0]){ 
            let item = this.Data.cartList.filter(item=>item.id == product.id)[0];
            item.cartCount = product.cartCount;  
        }
        else{           
            this.Data.cartList.push(product);
        }        
        this.Data.cartList.forEach(product=>{
            this.Data.totalPrice = this.Data.totalPrice + (product.cartCount * product.newPrice);
            this.Data.totalCartCount = this.Data.totalCartCount + product.cartCount;
        });

        message = 'The product ' + product.name + ' has been added to cart.'; 
        status = 'success';          
        this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });
    }

    public resetProductCartCount(product:Product){
        product.cartCount = 0;
        let compareProduct = this.Data.compareList.filter(item=>item.id == product.id)[0];
        if(compareProduct){
            compareProduct.cartCount = 0;
        };
        let wishProduct = this.Data.wishList.filter(item=>item.id == product.id)[0];
        if(wishProduct){
            wishProduct.cartCount = 0;
        }; 
    }

    public getBrands(){
        return [  
            { name: 'aloha', image: 'assets/images/brands/aloha.png' },
            { name: 'dream', image: 'assets/images/brands/dream.png' },  
            { name: 'congrats', image: 'assets/images/brands/congrats.png' },
            { name: 'best', image: 'assets/images/brands/best.png' },
            { name: 'original', image: 'assets/images/brands/original.png' },
            { name: 'retro', image: 'assets/images/brands/retro.png' },
            { name: 'king', image: 'assets/images/brands/king.png' },
            { name: 'love', image: 'assets/images/brands/love.png' },
            { name: 'the', image: 'assets/images/brands/the.png' },
            { name: 'easter', image: 'assets/images/brands/easter.png' },
            { name: 'with', image: 'assets/images/brands/with.png' },
            { name: 'special', image: 'assets/images/brands/special.png' },
            { name: 'bravo', image: 'assets/images/brands/bravo.png' }
        ];
    }

    public getCountries(){
        return [ 
        ]
    }

    public getMonths(){
        return [
            { value: '01', name: 'January' },
            { value: '02', name: 'February' },
            { value: '03', name: 'March' },
            { value: '04', name: 'April' },
            { value: '05', name: 'May' },
            { value: '06', name: 'June' },
            { value: '07', name: 'July' },
            { value: '08', name: 'August' },
            { value: '09', name: 'September' },
            { value: '10', name: 'October' },
            { value: '11', name: 'November' },
            { value: '12', name: 'December' }
        ]
    }

    public getYears(){
        return ["2018", "2019", "2020", "2021", "2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030" ]
    }

    public getDeliveryMethods(){
        return [
            { value: 'free', name: 'Free Delivery', desc: '$0.00 / Delivery in 7 to 14 business Days' },
            { value: 'standard', name: 'Standard Delivery', desc: '$7.99 / Delivery in 5 to 7 business Days' },
            { value: 'express', name: 'Express Delivery', desc: '$29.99 / Delivery in 1 business Days' }
        ]
    }

} 